import DetailReview from "../../components/meview/chipreview/DetailReview";
import TopArrowback from "../../components/meview/chipreview/TopArrowback";


function MeviewChipReview() {
  return (
    <div>
      <TopArrowback />
      <DetailReview />
    </div>
  );
}

export default MeviewChipReview;
